/* eslint-disable react/jsx-no-target-blank */
import "./footer.scss";
import iatalogo from "../../assets/images/iata.png";
import arclogo from "../../assets/images/arc-accredited.png";
// import saudiogo from "../../assets/images/soudi-arabia.jpg";
import acclogo from "../../assets/images/accenriated.png";
import googlelogo from "../../assets/images/google-verified.png";
import modaliatalogo from "../../assets/images/lataverification.jpg";
import modalarcverification from "../../assets/images/arcverification.jpg";
import modalbbbcertification from "../../assets/images/bbb-certificate.jpg";

import Modal from "react-bootstrap/Modal";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { appRoutesConst } from "app/navigation";
import StickySocialMedia from "components/common/StickySocialMedia";
import moment from "moment";
// import Button from "react-bootstrap/Button";

const Footer = () => {
  const [show, setShow] = useState(false);
  const [selectedImage, setImage] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (img) => {
    setShow(true);
    setImage(img);
  };
  return (
    <>
      <footer>
        <div className="container">
          <div className="row justify-content-center">
            <div className=" col-md-8 col-sm-11">
              <div className="text-center partners-logo d-flex justify-content-center">
                <a
                  onClick={() => handleShow(modaliatalogo)}
                  className="partner-logo-img"
                >
                  <img src={iatalogo} alt="iata-logo" className="img-fluid" />
                </a>
                <a
                  className="partner-logo-img"
                  onClick={() => handleShow(modalarcverification)}
                >
                  <img src={arclogo} alt="arc-logo" className="img-fluid" />
                </a>
                {/* <a
                  className="partner-logo-img"
                  onClick={() => handleShow(modalbbbcertification)}
                >
                  <img src={saudiogo} alt="" className="img-fluid" />
                </a> */}
                {/* <a
                  className="partner-logo-img"
                  onClick={() => handleShow(modalbbbcertification)}
                >
                  <img src={acclogo} alt="acc-logo" className="img-fluid" />
                </a> */}
                <a
                  href="https://shorturl.at/ijtuK"
                  target="_blank"
                  className="partner-logo-img"
                >
                  <img
                    src={googlelogo}
                    alt="Chicago Hajj Google Reviews"
                    className="img-fluid"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-top">
          <div className=" container">
            <div className="footer-top-text-link">
              {/* footer item section */}
              <div className="footer-top-text-item">
                <p className="font-size-16 footer-links-title text-white font-weight-semibold">
                  About Us
                </p>
                <p className="font-size-14 text-white footer-desc">
                10yearsaudivisa is a subsidiary company of Chicago Hajj and Umrah, we stand out as the leading Hajj and Umrah travel agency based in the USA. With over 20 years of experience and having served more than 100,000 satisfied customers, our team of dedicated professionals specializes in crafting personalized, all-inclusive packages that cater to the unique needs of our pilgrims. Our exceptional service, comprehensive guidance, and deep understanding of religious customs make us the preferred choice for Muslims seeking a transformative pilgrimage experience.
                </p>
              </div>
              {/* footer item section */}
              <div className="footer-top-text-item">
                <p className="font-size-16 footer-links-title text-white font-weight-semibold">
                  Quick Links
                </p>
                <div className="footer-links-content  d-flex">
                  {/* <div className="footer-links-left w-50">
                    <Link
                      to={`packages/Umrah`}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Umrah Inquiry
                    </Link>
                    <Link
                      to="/?tab=eVisa"
                      onClick={() => {
                        let element = document.getElementById(
                          "flight-search-container"
                        );
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Umrah Visa
                    </Link>
                    <Link
                      to={appRoutesConst.imagesGallary}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Image Gallery
                    </Link>
                    <Link
                      to={appRoutesConst.videosGallary}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Video Gallery
                    </Link>
                    <Link
                      to={appRoutesConst.reviews}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Reviews
                    </Link>
                  </div> */}
                  <div className="footer-links-right w-50">
                    <Link
                      to={appRoutesConst.contactus}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Contact Us
                    </Link>
                    <Link
                      to={appRoutesConst.privacypolicy}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Privacy Policy
                    </Link>

                    <Link
                      to={appRoutesConst.termsandconditions}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Terms and Conditions
                    </Link>

                    {/* <Link
                      to={appRoutesConst.faqs}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      FAQs
                    </Link>
                    <Link
                      to={appRoutesConst.blogs}
                      className="text-white text-decoration-none font-size-14 d-block mb-3"
                    >
                      Blogs
                    </Link> */}
                  </div>
                </div>
              </div>
              {/* footer item section */}
              <div className="footer-top-text-item  second-footer-text-item">
                <p className="font-size-16 footer-links-title text-white font-weight-semibold">
                  Address & Contact
                </p>
                <a
                  href="http://maps.google.com/?q=2818 W Peterson Ave Chicago, IL 60659"
                  target={"_blank"}
                  className="d-flex align-items-center mb-3 text-black"
                >
                  <div className="add-icon">
                    <i class="fa-solid fa-location-dot"></i>
                  </div>
                  <div className="add-text text-white font-size-16">
                    2818 W Peterson Ave Chicago, IL 60659
                  </div>
                </a>
                <a
                  href="mailto:info@chicagohajj.com"
                  className="d-flex align-items-center mb-3 text-black"
                >
                  <div className="add-icon">
                    <i class="fa-regular fa-envelope"></i>
                  </div>
                  <div className="add-text text-white font-size-16">
                    info@chicagohajj.com
                  </div>
                </a>
                <a
                  href="tel:7734658500"
                  className="d-flex align-items-center mb-3 text-black"
                >
                  <div className="add-icon">
                    <i class="fa-solid fa-phone"></i>
                  </div>
                  <div className="add-text text-white font-size-16">
                    (773) 465-8500
                  </div>
                </a>
              </div>
              {/* footer item section */}
              <div className="footer-top-text-item second-footer-text-item">
                <p className="font-size-16 footer-links-title text-white font-weight-semibold">
                  Follow Us
                </p>
                <div className="footer-social-section">
                  <a
                    href="https://www.facebook.com/ChicagoHajjandUmrah/"
                    title="Facebook"
                    target="_blank"
                    className="d-flex align-items-center mb-3 text-body"
                  >
                    <div className="add-icon">
                      <i class="fa-brands fa-facebook"></i>
                    </div>
                    <div className="add-text text-white font-size-16">
                      Facebook
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/Chicagohajj"
                    target="_blank"
                    title="Twitter"
                    className="d-flex text-body align-items-center mb-3"
                  >
                    <div className="add-icon">
                      <i class="fa-brands fa-twitter"></i>
                    </div>
                    <div className="add-text text-white font-size-16">
                      Twitter
                    </div>
                  </a>
                  <a
                    href="https://www.instagram.com/chicagohajjandumrah/"
                    title="Instagram"
                    target="_blank"
                    className="d-flex text-body align-items-center mb-3"
                  >
                    <div className="add-icon">
                      <i class="fa-brands fa-instagram"></i>
                    </div>
                    <div className="add-text text-white font-size-16">
                      Instagram
                    </div>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCH1x418TTBmcVTJkB32-sCA"
                    title="Youtube"
                    target="_blank"
                    className="text-body d-flex align-items-center mb-3"
                  >
                    <div className="add-icon">
                      <i class="fa-brands fa-youtube"></i>
                    </div>
                    <div className="add-text text-white font-size-16">
                      Youtube
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="text-center">
              <p className="text-white mb-0">
                We offer services across all cities in the USA.
              </p>
              <p className="text-white mb-0">
                Copyrights © {moment().format("YYYY")} 10yearsaudivisa (Powered by Chicago Hajj). All Rights
                Reserved
                 {/* | v0.0.12 */}
              </p>
              {/* <div className="footer-bottom-links">
              <a href="#" className="text-white">Chicago</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">Florida</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">Houston</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">New york</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">San Francisco</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">New Jersey</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">Los Angeles</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">Dallas</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">California</a> <span className="font-weight-normal px-1 text-white">|</span>
              <a href="#" className="text-white">Wasington D.C.</a>
            </div> */}
            </div>
          </div>
        </div>
        <StickySocialMedia />
      </footer>
      <Modal
        className="modal-footer-logo"
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <img src={selectedImage} alt="Upload Image" className="img-fluid" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Footer;
