// import Blogs from "pages/Blogs";
// import FAQs from "pages/FAQs";
// import BlogsDetails from "containers/Blogs/BlogsDetail";
import ContactUs from "pages/ContactUs/ContactUs";
import { lazy } from "react";
import { appRoutesConst } from "./navigation";

// Components

// Home Page
const Home = lazy(() => import("../pages/Home/Home"));

// terms
const TermsConditionPage = lazy(() =>
  import("../pages/TermsCondition/TermsConditionPage")
);

const PrivacyPolicyPage = lazy(() =>
  import("../pages/PrivacyPolicy/PrivacyPolicyPage")
);

const UmrahPackages = lazy(() => import("../pages/Umrah/UmrahPacakages"));

const Nusuk = lazy(() => import("../pages/Nusuk/Nusuk"));
const GlobalTour = lazy(() => import("../pages/GlobalTour/GlobalTour"));
const GlobalTourDetail = lazy(() =>
  import("../pages/GlobalTour/GlobalTourDetailPage")
);
const TenYearVisa = lazy(() => import("../pages/TenYearVisa/TenYearVisa"));
// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    path: appRoutesConst.index,
    element: Home,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.tenYearsSaudiVisa,
    element: Home,
    isOpenRoute: true,
  },

  // package routes

  // hotels routes

  // blog routes

  //  blog routes

  //videos gallary

  // contact us
  {
    path: appRoutesConst.contactus,
    element: ContactUs,
    isOpenRoute: true,
  },
  // terms
  {
    path: appRoutesConst.termsandconditions,
    element: TermsConditionPage,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.privacypolicy,
    element: PrivacyPolicyPage,
    isOpenRoute: true,
  },

  {
    path: appRoutesConst.umrahpackages,
    element: UmrahPackages,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.nusuk,
    element: Nusuk,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.globalTour,
    element: GlobalTour,
    isOpenRoute: true,
  },
  {
    path: appRoutesConst.globalTourDetail,
    element: GlobalTourDetail,
    isOpenRoute: true,
  },

  {
    path: appRoutesConst.tenYearVisa,
    element: TenYearVisa,
    isOpenRoute: true,
  },
];
