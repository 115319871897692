import { appRoutesConst } from "app/navigation";

export const menuItemsData = [
  {
    title: "Home",
    url: "/",
  },
  // {
  //   title: "Umrah Packages",
  //   url: "",
  //   submenu: [
  //     {
  //       title: "Standard Packages",
  //       url: "",
  //       submenu: [
  //         {
  //           title: "4 Days Umrah Package",
  //           url: "/packages/Umrah/standard-4-days-package",
  //         },
  //         {
  //           title: "6 Days Umrah Package",
  //           url: "/packages/Umrah/standard-6-days-package",
  //         },
  //         {
  //           title: "8 Days Umrah Package",
  //           url: "/packages/Umrah/standard-8-days-package",
  //         },
  //         {
  //           title: "10 Days Umrah Package",
  //           url: "/packages/Umrah/standard-10-days-package",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Economy Packages",
  //       url: "",
  //       submenu: [
  //         {
  //           title: "4 Days Umrah Package",
  //           url: "/packages/Umrah/economy-4-days-package",
  //         },
  //         {
  //           title: "6 Days Umrah Package",
  //           url: "/packages/Umrah/economy-6-days-package",
  //         },
  //         {
  //           title: "8 Days Umrah Package",
  //           url: "/packages/Umrah/economy-8-days-package",
  //         },
  //         {
  //           title: "10 Days Umrah Package",
  //           url: "/packages/Umrah/economy-10-days-package",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Premium Packages",
  //       submenu: [
  //         {
  //           title: "4 Days Umrah Package",
  //           url: "/packages/Umrah/premium-4-days-package",
  //         },
  //         {
  //           title: "6 Days Umrah Package",
  //           url: "/packages/Umrah/premium-6-days-package",
  //         },
  //         {
  //           title: "8 Days Umrah Package",
  //           url: "/packages/Umrah/premium-8-days-package",
  //         },
  //         {
  //           title: "10 Days Umrah Package",
  //           url: "/packages/Umrah/premium-10-days-package",
  //         },
  //       ],
  //     },
  //     {
  //       title: "Luxury Packages",
  //       submenu: [
  //         {
  //           title: "4 Days Umrah Package",
  //           url: "/packages/Umrah/luxury-4-days-package",
  //         },
  //         {
  //           title: "6 Days Umrah Package",
  //           url: "/packages/Umrah/luxury-6-days-package",
  //         },
  //         {
  //           title: "8 Days Umrah Package",
  //           url: "/packages/Umrah/luxury-8-days-package",
  //         },
  //         {
  //           title: "10 Days Umrah Package",
  //           url: "/packages/Umrah/luxury-10-days-package",
  //         },
  //       ],
  //     },
  //     // {
  //     //   title: "Ramadan Packages",
  //     //   submenu: [
  //     //     {
  //     //       title: "3 Days Umrah In Ramadan",
  //     //       url: "/packages/Umrah/3-Days-Umrah-Packages-in-Ramadan",
  //     //     },
  //     //     {
  //     //       title: "1st Ashrah In Ramadan",
  //     //       url:"/packages/Umrah/Umrah-in-Ramadan-1st-10-days"
  //     //     },
  //     //     {
  //     //       title: "2nd Ashrah In Ramadan",
  //     //       url:"/packages/Umrah/Umrah-in-Ramadan-2nd-10-days"
  //     //     },
  //     //     {
  //     //       title: "3rd Ashrah In Ramadan",
  //     //       url:"/packages/Umrah/Umrah-in-Ramadan-last-10-days"
  //     //     },
  //     //     {
  //     //       title: "Etikaaf Umrah In Ramadan",
  //     //       url:"/packages/Umrah/Etikaaf-Package"
  //     //     },
  //     //     {
  //     //       title: "Eid-ul-fitr In Haram",
  //     //       url:"/packages/Umrah/5-Days-of-Eid-ul-Fitar-in-Makkah-and-Madinah"
  //     //     },
  //     //   ],
  //     // },
  //   ],
  // },
  // {
  //   title: "Hajj Packages",
  //   url: "/packages/Hajj",
  //   // submenu: [
  //   //   {
  //   //     title: "Standard",
  //   //     url: "",
  //   //     submenu: [
  //   //       {
  //   //         title: "Standard 1",
  //   //         url: "",
  //   //       },
  //   //       {
  //   //         title: "Standard 2",
  //   //         url:""
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Gold",
  //   //     url: "",
  //   //     submenu: [
  //   //       {
  //   //         title: "Gold 1",
  //   //         url: "",
  //   //       },
  //   //       {
  //   //         title: "Gold 2",
  //   //         url:""
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Platanium",
  //   //     submenu: [
  //   //       {
  //   //         title: "Platanium 1",
  //   //         url: "",
  //   //       },
  //   //       {
  //   //         title: "Platanium 2",
  //   //         url:""
  //   //       },
  //   //     ],
  //   //   },
  //   // ],
  // },

  // {
  //   // title: "Tour Packages",
  //   // url: "/packages/Other",
  //   // submenu: [
  //   //   {
  //   //     title: "Standard",
  //   //     url: "",
  //   //     submenu: [
  //   //       {
  //   //         title: "Standard 1",
  //   //         url: "",
  //   //       },
  //   //       {
  //   //         title: "Standard 2",
  //   //         url:""
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Gold",
  //   //     url: "",
  //   //     submenu: [
  //   //       {
  //   //         title: "Gold 1",
  //   //         url: "",
  //   //       },
  //   //       {
  //   //         title: "Gold 2",
  //   //         url:""
  //   //       },
  //   //     ],
  //   //   },
  //   //   {
  //   //     title: "Platanium",
  //   //     submenu: [
  //   //       {
  //   //         title: "Platanium 1",
  //   //         url: "",
  //   //       },
  //   //       {
  //   //         title: "Platanium 2",
  //   //         url:""
  //   //       },
  //   //     ],
  //   //   },
  //   // ],
  // },
  // {
  //   title: "Tour Packages",
  //   url: "/tour-packages",
  // },
  // {
  //   title: "Blogs",
  //   url: "/blogs",
  // },
  // {
  //   title: "Nusuk Hajj",
  //   url: "/nusuk",
  // },
  // {
  //   title: "Mofa Services",
  //   url: "/10-year-saudi-visa",
  // },
  // {
  //   title: "Privacy Policy",
  //   url: appRoutesConst.privacypolicy,
  // },
  // {
  //   title: "Terms and Conditions",
  //   url: appRoutesConst.termsandconditions,
  // },

  {
    title: "Contact Us",
    url: "/contactus",
  },
];
